.footer {
  height: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  align-items: center;
  transition: all 0.2s;
  padding: 0 20px;
}
