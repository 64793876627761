body {
  /* margin-top: 50px; */
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #39464e;
}

.navbar-default {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-left: 200px; */
  z-index: 50;
}

/*main side bar*/
.msb {
  width: 270px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: fixed;
  left: 0;
  top: 0;
  right: auto;
  min-height: 100%;
  overflow-y: auto;
  white-space: nowrap;
  height: 100vh;
  z-index: 100;
}

.msb .navbar {
  border: none;
  margin-left: 0;
  background-color: inherit;
}

.msb .navbar-header {
  width: 100%;
  border-bottom: 1px solid #e0eaed;
  margin-bottom: 20px;
  background: #fff;
}

.msb .navbar-nav .panel {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  background: inherit;
}

.msb .navbar-nav li {
  display: block;
  width: 100%;
}

.msb .navbar-nav li a {
  padding: 15px;
  color: #5f5f5f;
}

.msb .navbar-nav li a .glyphicon,
.msb .navbar-nav li a .fa {
  margin-right: 8px;
}

.btnCreate {
  margin-top: 20px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  transition: 0.3s;
}

.btnCreate:hover {
  background-color: rgba(30, 50, 191, 1);
  color: #fff !important;
}

.side-inactive {
  background-color: #fff;
  color: #1e32bfcc !important;
  border: 1px solid #1e32bfcc;
}

.side-active {
  background-color: #1e32bfcc;
  color: #fff;
}

.msb .nb {
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 30px;
  overflow: hidden;
}

.checkFolder {
  /* width: 200px; */
  text-wrap: wrap;
  margin-top: 20px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.2s;
}

.checkFolder input {
  font-size: 14px;
  padding: 4px 6px;
  height: auto;
}

.editBtn {
  display: flex;
  gap: 5px;
}

.editBtn .saveBtn,
.editBtn .cancelBtn {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  color: #fff;
  background-color: rgba(30, 50, 191, 0.8);
  border-radius: 4px;
  border: 0;
  transition: all 0.2s;
}

.editBtn .cancelBtn {
  background-color: rgba(140, 27, 171, 0.8);
}

.editBtn .cancelBtn:hover {
  background-color: rgba(140, 27, 171, 1);
}

.editBtn .saveBtn:hover {
  background-color: rgba(30, 50, 191, 1);
}

.uploadList {
  /* padding-left: 100px; */
  text-align: center;
}

.uploadList ul {
  width: 100%;
  text-wrap: nowrap;
  padding: 0;
}

.uploadList .upload {
  margin: auto;
}

ul.nv,
ul.ns {
  position: relative;
  padding: 0;
  list-style: none;
}

.nv li {
  display: block;
  position: relative;
}

.nv li::before {
  clear: both;
  content: "";
  display: table;
}

.nv li a {
  color: #444;
  padding: 10px 25px;
  display: block;
  vertical-align: middle;
}

.nv li a .ic {
  font-size: 16px;
  margin-right: 5px;
  font-weight: 300;
  display: inline-block;
}

.nv .ns li a {
  padding: 10px 50px;
}

/*main content wrapper*/
.mcw {
  padding-top: 72px;
  height: 100%;
  padding-left: 270px;
  transition: all 0.3s;
}

body.msb-x .mcw {
  padding-left: 0;
}

/*globals*/
a,
a:focus,
a:hover {
  text-decoration: none;
}

.inbox .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.inbox ul,
.inbox li {
  margin: 0;
  padding: 0;
}

.inbox ul li {
  list-style: none;
}

.inbox ul li a {
  display: block;
  padding: 10px 20px;
}

.image {
  width: 30%;
  padding: 20px 0;
}

ul {
  list-style: none;
  margin-top: 50px;
}

.upload .iconlist {
  font-size: 20px;
}

.btnCreate .iconlist {
  color: #fff;
  font-size: 15px;
  --bs-btn-box-shadow: none;
}

li {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  font-size: 17px;
}

.imagedata {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px;
}

.user-div {
  margin-top: -10px;
  margin-bottom: 15px;
}

.user-email {
  font-size: 16px;
  font-weight: 500;
}

.logout {
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.logout .logtxt {
  font-size: 17px;
  font-weight: 500;
  color: #8c1bab;
}

.btnLog {
  border: none;
  display: flex;
  gap: 2px;
  align-items: center;
  border-radius: 3px;
  transition: 0.3s;
  background: linear-gradient(135deg, #f761a1 0%, #8c1bab 100%);
}

.btnLog:hover {
  background: linear-gradient(135deg, #8c1bab 0%, #f761a1 100%);
}

.form-check-label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

.edit,
.deleteBtn {
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.edit {
  border: none;
  background: none;
}

.edit svg {
  color: rgba(30, 50, 191, 0.8);
  font-size: 18px;
}

.deleteBtn {
  border: none;
  background: none;
}

.deleteBtn svg {
  color: rgba(140, 27, 171, 0.8);
  font-size: 20px;
}

.edit svg:hover {
  color: rgba(30, 50, 191, 1);
}

.deleteBtn SVG:hover {
  color: rgba(140, 27, 171, 1);
}

.imagedata .user-profile {
  width: 175px;
  text-align: center;
  background: rgba(30, 50, 191, 0.1);
}

#left {
  padding-left: 70px !important;
}

.active-folder {
  background-color: #d0d5f2;
  border: 1px solid #1e32bfcc;

}

#hamburger {
  background-color: #1e32bfe6;
  border: 0;
  padding: 5px 10px;
  color: white;
}

body .navbar {
  padding: 20px 0;
  padding-left: 282px;
}

body.msb-x .navbar {
  margin-left: 0;
  padding: 20px 15px;
}

.editName {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

.editFolderNameInput {
  color: #7c7d81;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #cccccc;
  padding: 6px 0px 6px 7px;
  outline: none;
}

.btnhandle.cancelBtn {
  background-color: rgba(140, 27, 171, 0.8);
  border: 0;
  color: #fff;
  font-weight: 500;
  transition: 0.2s;
  padding: 5px 18px;
}

.btnhandle.cancelBtn:hover {
  background-color: rgba(140, 27, 171, 1);
  color: #fff;
  font-weight: 500;
}

.btnhandle.updateBtn {
  background-color: rgba(30, 50, 191, 0.8);
  border: 0;
  transition: 0.2s;
  padding: 5px 18px;
}

.btnhandle.updateBtn:hover {
  background-color: rgba(30, 50, 191, 1);
  color: #fff;
  font-weight: 400;
}

.noRecord {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 0;
}

.hide-scrollbar {
  overflow: auto;
  /* border: 1px solid #ccc; */
  scrollbar-width: thin;
}

.swal2-confirm {
  font-size: 14px;
  padding: 6px 15px;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(30, 50, 191, 0.8) !important;
  border: 0;
  color: #fff;
  font-weight: 500;
  transition: 0.2s;
  scrollbar-width: thin;
  box-shadow: none;
}

.swal2-cancel {
  width: 135px;
  font-size: 14px;
  padding: 6px 15px;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(140, 27, 171, 0.8) !important;
  border: 0;
  color: #fff;
  font-weight: 500;
  transition: 0.2s;
  scrollbar-width: thin;
  box-shadow: none;
}

.swal2-warning {
  border-color: #8c1bab !important;
  color: #8c1bab !important;
}

.swal2-popup {
  border-radius: 15px;
}

.swal2-html-container {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #7c7d81 !important;
}

.swal2-title {
  font-size: 20px;
  font-weight: 600;
}

.short-txt {
  width: 160px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: norwrap;
  cursor: pointer;
  margin-top: 10px;
}