@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

#root {
  position: relative;
}

body {
  margin-top: 0;
  background-color: #fafbff;
}

.main_table_wrapper {
  height: calc(100vh - 67px);
}

.tableBtn button {
  font-size: 14px;
  font-weight: 300;
  transition: 0.3s;
}

.tableBtn button:hover {
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.7));
}

.search {
  align-items: center;
  gap: 0;
  height: 30px;
}

.search span {
  background-color: rgba(30, 50, 191, 0.8);
  color: #fff;
  font-size: 16px;
  height: 100%;
  padding: 0px 10px;
  border-radius: 6px 0 0 6px;
  border: none;
  font-weight: bold;
  line-height: 30px;
}

.search input {
  font-size: 14px;
  height: 100%;
  padding: 0px 10px;
  border-radius: 0 6px 6px 0;
  border: 1px solid rgba(30, 50, 191, 0.8);
  outline: none;
}

.sc-gEvDqW.jDljLp.rdt_TableHead,
.sc-gEvDqW .cNgHAE .rdt_TableHead {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.eYzbsH,
.fVWVEn {
  font-size: 13px !important;
  padding: 10px 0;
  color: #7c7d81 !important;
}

.navbar-default {
  transition: all 0.3s;
}

.msb {
  transition: all 0.3s;
}

.msb.msb-x.msb-hide {
  margin-left: -270px;
  /* transition: all 0.3s; */
}

body.msb-x #left {
  padding-left: 20px !important;
}

.gKFaMm,
.bpmoUE {
  gap: 10px;
  font-size: 18px;
  transition: 0.3s;
}

.deleteIcon {
  color: rgba(140, 27, 171, 1);
  margin-top: 4px;
}

.downloadIcon {
  color: rgba(30, 50, 191, 1);
}

.deleteIcon:hover {
  filter: drop-shadow(2px 4px 6px black);
}

.downloadIcon {
  font-size: 16px;
}

.downloadIcon:hover {
  filter: drop-shadow(2px 2px 8px black);
}

.Nfhbm,
.cMiPmJ {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  border-radius: 10px;
}

.fzvXhU,
.dODGfY,
.kdoubu {
  background: rgba(30, 50, 191, 0.1) !important;
  font-weight: 500;
  font-size: 15px;
}

.gjoprN,
.hovPcd {
  padding: 0px 10px;
  width: fit-content;
}

.bRGCiY,
.dxEqmY {
  color: #333 !important;
}

.bOmZm {
  outline: 0;
}

.rdt_TableBody>div:nth-child(even),
.sc-dAbbbq .jaZrIV .rdt_TableBody>div:nth-child(even) {
  background-color: rgba(238, 238, 238, 0.8);
}

.rdt_TableBody>div:hover,
.sc-dAbbbq .jaZrIV .rdt_TableBody>div:hover {
  background-color: rgba(238, 238, 238, 0.5);
}

.hsWuNV {
  min-width: 120px !important;
}

@media (0 < width < 992px) {

  .sc-gEvDqW.jDljLp.rdt_TableHead,
  .sc-gEvDqW .cNgHAE .rdt_TableHead {
    font-size: 14px !important;
  }

  .eYzbsH,
  .fVWVEn {
    font-size: 12px !important;
    padding: 10px 0;
    color: #7c7d81 !important;
  }

  .sidebar-hide {
    margin-left: -270px;
  }

  .msb {
    position: absolute;
    top: 0;
    left: 0;
  }

  .msb {
    position: absolute;
  }

  .mcw {
    padding-left: 0;
  }
}