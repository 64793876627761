.download {
    background-color: rgba(30, 50, 191, 0.8);
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 17px;
    border-radius: 5px;

}

.delete {
    background-color: #8C1BAB;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 17px;
    border-radius: 5px;

}

.tableBtn {
    display: flex;
    gap: 11px;
}

.dropdown {
    display: flex;
    justify-content: end;
    align-items: center;

}

.deleteIcon {
    cursor: pointer;
}

.downloadIcon {
    cursor: pointer;
    color: black;
}


.downloadLink {
    text-decoration: none;
}
.reactTable div{
    padding: 0;
}