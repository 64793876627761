.upload {
  display: flex;
  gap: 7px;
  align-items: center;
  font-size: 16px;
  border: none;
  background: linear-gradient(135deg, #f761a1 0%, #8c1bab 100%);
  transition: all 0.3s;
}

.upload:hover {
  background: linear-gradient(135deg, #8c1bab 0%, #f761a1 100%);
}

.modal-content {
  border: 1px solid #333;
}

.handle {
  display: flex;
  gap: 10px;
}

.upload-closeBtn {
  display: flex;
  justify-content: space-between;
}

.imcorss {
  cursor: pointer;
}

.btnhandle {
  font-size: 14px;
  padding: 6px 15px;
  height: auto;
  cursor: pointer;
  border-radius: 5px;
}

.select {
  width: 200px;
  font-size: 15px;
  font-weight: 400;
  padding: 4px 6px;
  border-radius: 20px;
  border: 1px solid #cccccc;
  outline: none;
  color: #918d8d;
}

.modal-heading {
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(45deg, #f761a1, #8c1bab);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.choose-file {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 5px;
}

.upload-sec {
  border: 1px dashed #cccccc;
  border-radius: 4px;
  padding: 20px 4px;
  position: relative;
}

.upload-sec p {
  font-size: 16px;
  font-weight: 500;
}

.upload-sec input {
  width: 200px;
  color: #0059df;
  opacity: 0;
}

.upload-sec .browse svg,
.upload-sec a {
  color: rgba(30, 50, 191, 1);
}

.browse label {
  color: rgba(30, 50, 191, 1);
  font-size: 15px;
  font-weight: 500;
}

.btnhandle.closeBtn {
  background-color: rgba(140, 27, 171, 0.8);
  border: 0;
  color: #fff;
  font-weight: 500;
  transition: 0.2s;
}

.btnhandle.uploadBtn {
  background-color: rgba(30, 50, 191, 0.8);
  border: 0;
  transition: 0.2s;
}

.btnhandle.closeBtn:hover {
  background-color: rgba(140, 27, 171, 1);
  color: #fff;
  font-weight: 500;
}

.btnhandle.uploadBtn:hover {
  background-color: rgba(30, 50, 191, 1);
  color: #fff;
  font-weight: 400;
}

.modal-header .btn-close {
  border: 2px solid #aba9a9;
  padding: 6px;
  font-size: 10px;
  border-radius: 50%;
  transition: 0.2s;
  --bs-btn-close-focus-shadow: none;
}

.modal-header .btn-close:hover {
  border: 2px solid rgba(140, 27, 171, 1);
  /* background-color: #0059df; */
}

.file-upload-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-upload-wrapper input {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.modal-body .form-select:focus {
  box-shadow: unset;
  border: 1px solid #7c7d81;
}
.form-select {
  font-size: 13px !important;
  font-weight: 400;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.spin {
  animation: spin 1000ms linear infinite;
}
