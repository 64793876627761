.handle {
    margin-top: 20px;
}
.folder-name{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
}
.editInput{
    color: #7c7d81;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #cccccc;
    padding: 6px 0px 6px 7px;
    outline: none;
}
.createCloseBtn{
    background-color: rgba(140, 27, 171, 0.8);
    border: 0;
    color: #fff;
    font-weight: 500;
    transition: 0.2s;
    padding: 5px 18px;
}
.createCloseBtn:hover,.createCloseBtn:active{
    background-color: rgba(140, 27, 171, 1);
    color: #fff;
    font-weight: 500;
}
.createSaveBtn{
    background-color: rgba(30, 50, 191, 0.8);
    border: 0;
    transition: 0.2s;
    padding: 5px 18px;
}
.createSaveBtn:hover{
    background-color: rgba(30, 50, 191, 1);
    color: #fff;
    font-weight: 400;
}