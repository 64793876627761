.main-container {
    display: flex;
    justify-content: center;
}

.login {
    width: 60%;
    max-width: 500px;
    min-width: 300px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form-login{
    /* border:1px solid rgb(89, 50, 234); */
    
}

.btn-login {
    margin-top: 10px;
}
.btn-login button{
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%);
    color: #fff;
}
.btn-login button:hover{
    background: linear-gradient(135deg, #8C1BAB 0%, #F761A1 100%);

}
.main-container {
    height: 100vh;
    display: flex;
    align-items: center;
}

.form {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.spin {
    animation: spin 1000ms linear infinite;
}


.login .btn {
    width: 100%;
    color: white;
    height: 47px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 22px;
}

.btn:hover {
    background-color: #777777;
    color: white;
}

.form-control {
    height: 45px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
}

.form-login label {
    color: #ce46a4;
    font-size: 15px;
    font-weight: 500;

}

.form-control::placeholder {
    color: gray;
}

.heading {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    background: linear-gradient(45deg, #F761A1, #8C1BAB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}